import { useStaticQuery, graphql } from 'gatsby'

export const TabsDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allTabsDataJson, images } = useStaticQuery(
    graphql`
      query queryTabsDataContent {
        allTabsDataJson {
          edges {
            node {
              tabs {
                title
                content {
                  text
                }
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "tabs" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 500, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [allTabsDataJson.edges[0].node.tabs, images.edges]
}
