import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { array } from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

import ThemeContext from '@contexts/themeContext'

import ArrowLeft from '@images/icons/arrow-left.svg'
import ArrowRight from '@images/icons/arrow-right.svg'
import Close from '@images/icons/close.svg'

const PopupGallery = styled(({ data, className }) => {
  const { activeGalleryPopup, selectedImage, togglePopupGallery } = useContext(
    ThemeContext
  )

  const [currentActiveKey, setCurrentActiveKey] = useState(null)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(key)
  }

  return (
    <div className={`${className} ${activeGalleryPopup ? 'active' : ''}`}>
      <button
        className="popup-overlay"
        onClick={() => togglePopupGallery(!activeGalleryPopup, 0)}
      >
        &nbsp;
      </button>
      <div className="wrapper">
        <Container>
          <div className="wrapper-inner">
            <span className="h3">Gallery</span>
            <Row>
              <Col md="12" xl="3" className="order-1 order-lg-0">
                <Row className="m-0 justify-content-between">
                  {data.map((item, index) => {
                    return (
                      <div
                        className={`image-box ${
                          (currentActiveKey === null &&
                            index === selectedImage) ||
                          currentActiveKey === index
                            ? 'active'
                            : ''
                        }`}
                        key={index}
                      >
                        <button onClick={() => toggleActiveKey(index)}>
                          <BackgroundImage
                            className="image"
                            fluid={item.node.childImageSharp.fluid}
                          />
                        </button>
                      </div>
                    )
                  })}
                </Row>
              </Col>
              <Col md="12" xl="9" className="pl-xl-0 gallery-slider-wrapper">
                <button
                  className="closed"
                  onClick={() => togglePopupGallery(!activeGalleryPopup, 0)}
                >
                  <img src={Close} alt="" />
                </button>
                <BackgroundImage
                  className="gallerySlider"
                  fluid={
                    data[currentActiveKey ? currentActiveKey : selectedImage]
                      .node.childImageSharp.fluid
                  }
                />
                <div className="nav-comp">
                  <button
                    className="prev"
                    onClick={() =>
                      toggleActiveKey(
                        currentActiveKey !== 0
                          ? currentActiveKey - 1
                          : data.length - 1
                      )
                    }
                  >
                    <img src={ArrowLeft} alt="" />
                  </button>
                  <span>
                    {currentActiveKey + 1}/{data.length}
                  </span>
                  <button
                    className="next"
                    onClick={() =>
                      toggleActiveKey(
                        currentActiveKey !== data.length - 1
                          ? currentActiveKey + 1
                          : 0
                      )
                    }
                  >
                    <img src={ArrowRight} alt="" />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
})`
  top: 100%;
  height: 100%;
  left: 0;
  position: fixed;
  top: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  right: 0;
  visibility: hidden;
  z-index: 1010;

  &.active {
    opacity: 1;
    top: 0;
    visibility: visible;
  }

  .container {
    @media (max-width: 1200px) {
      height: 100%;
      max-width: none;
      padding: 0;
      width: 100%;
    }
  }

  .gallery-slider-wrapper {
    position: static;

    @media (min-width: 1200px) {
      position: relative;
    }
  }

  .popup-overlay {
    border: none;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .wrapper-inner {
      background-color: #fff;
      padding: 20px 20px 20px 20px;
      height: 100%;

      @media (min-width: 1200px) {
        padding: 20px 0 69px 20px;
      }

      .h3 {
        color: #141414;
        font-family: Titillium Web !important;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 28px;
      }
    }

    .closed {
      position: absolute;
      top: 20px;
      right: 20px;
      text-align: right;

      @media (min-width: 1200px) {
        right: 35px;
        top: -55px;
      }
    }

    .gallerySlider {
      background-size: cover;
      background-position: center center;
      display: block;
      min-height: 350px;

      @media (min-width: 576px) {
        min-height: 355px;
      }

      @media (min-width: 1200px) {
        height: 100%;
        min-height: 490px;
      }
    }

    .nav-comp {
      align-items: center;
      bottom: -35px;
      color: #141414;
      display: flex;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      position: absolute;
      left: 0;

      @media (max-width: 568px) {
        margin-bottom: 20px !important;
        margin-top: 20px !important;
      }

      @media (max-width: 1200px) {
        bottom: 0;
        justify-content: center;
        position: relative;
        margin-bottom: 31px;
        margin-top: 31px;
      }

      button {
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        height: 24px;
        width: 24px;
      }

      button.prev {
        margin-right: 10px !important;
      }

      button.next {
        margin-left: 10px !important;
      }
    }

    .image-box {
      margin-bottom: 10px;
      position: relative;
      flex: 0 0 24%;
      max-width: 24%;

      &:nth-of-type(4n) {
        margin-right: 0;
      }

      @media (min-width: 1200px) {
        flex: 0 0 48%;
        max-width: 48%;

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          margin-bottom: 0;
        }
      }

      &:before {
        bottom: -6px;
        border: 3px solid #63a536;
        content: '';
        display: none;
        left: -6px;
        top: -6px;
        right: -6px;
        position: absolute;
      }

      &.active,
      &:hover {
        &:before {
          display: block;
        }
      }
    }

    .image {
      background-size: cover;
      background-position: center center;
      display: block;
      height: 80px;

      @media (min-width: 576px) {
        height: 140px;
      }

      @media (min-width: 1200px) {
        height: 115px;
      }
    }
  }
`

PopupGallery.propTypes = {
  data: array.isRequired
}

PopupGallery.defaultProps = {
  data: {}
}

export default PopupGallery
