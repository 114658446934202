import React from 'react'

import next from '@images/icons/arrow-right.svg'

export default ({ className, to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button button--text button--icon ${className}`}
    aria-label={to}
  >
    <img src={next} alt="next arrow"/>
  </button>
)
