import { useStaticQuery, graphql } from 'gatsby'

export const CardDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCardDataJson, icons } = useStaticQuery(
    graphql`
      query queryCardDataContent {
        allCardDataJson {
          edges {
            node {
              title
              cards {
                text
              }
            }
          }
        }
        icons: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "card" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
  )
  return [
    allCardDataJson.edges[0].node.title,
    allCardDataJson.edges[0].node.cards,
    icons.edges
  ]
}
