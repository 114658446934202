import { useStaticQuery, graphql } from 'gatsby'

export const SliderDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allSliderDataJson, images, sliderBg } = useStaticQuery(
    graphql`
      query querySliderDataContent {
        allSliderDataJson {
          edges {
            node {
              card {
                title
                text
                cta
              }
              sliders {
                title
                toptitle
                content {
                  text
                  sublist {
                    text
                  }
                }
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "slider" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 500, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        sliderBg: file(relativePath: { eq: "bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return [
    allSliderDataJson.edges[0].node.card,
    allSliderDataJson.edges[0].node.sliders,
    images.edges,
    sliderBg
  ]
}
