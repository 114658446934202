import { useStaticQuery, graphql } from 'gatsby'

export const PopupDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allPopupDataJson, popupImg } = useStaticQuery(
    graphql`
      query queryPopupDataContent {
        allPopupDataJson {
          edges {
            node {
              popup_in {
                title
                text
                cta {
                  more
                  help
                }
              }
              popup_overlay {
                title
                content {
                  text
                }
              }
            }
          }
        }
        popupImg: file(relativePath: { eq: "popup.png" }) {
          childImageSharp {
            fluid(maxWidth: 868, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return [
    allPopupDataJson.edges[0].node.popup_in,
    allPopupDataJson.edges[0].node.popup_overlay,
    popupImg
  ]
}
