import React from 'react'
import { string } from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ title, subTitle, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subTitle
            description
            author
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  const metaSubTitle = subTitle || site.siteMetadata.subTitle
  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={`%s | ${metaSubTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        }
      ]}
    >
      <link
        rel="stylesheet"
        media="screen"
        key="cloudfront-css"
        href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"
      />
    </Helmet>
  )
}

SEO.propTypes = {
  title: string,
  description: string
}

SEO.defaultProps = {
  title: null,
  description: null
}

export default SEO
