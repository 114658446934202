import { useStaticQuery, graphql } from 'gatsby'

export const GalleryDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allGalleryDataJson, gallery } = useStaticQuery(
    graphql`
      query queryGalleryDataContent {
        allGalleryDataJson {
          edges {
            node {
              title
            }
          }
        }
        gallery: allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "gallery" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [allGalleryDataJson.edges[0].node.title, gallery.edges]
}
