import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'

import { SecondTextDataContent } from './models'

const IntroText = styled(({ className }) => {
  const title = SecondTextDataContent()[0]
  const subtitle = SecondTextDataContent()[1]

  return (
    <div className={`${className}`}>
      <div id="product-documents" className="scroll-target"></div>
      <Container>
        <div className="inner">
          <span className="h1 text-xl-center">{title}</span>
          <p className="text-xl-center">{subtitle}</p>
        </div>
      </Container>
    </div>
  )
})`
  margin-top: 80px;
  position: relative;

  @media (min-width: 1200px) {
    margin-top: 150px;
  }

  .scroll-target {
    position: absolute;
    top: -120px;
  }

  .inner{
    @media (min-width: 1200px) {
      padding: 0 96px;
    }
  
    .h1 {
      color: #231e60;
      font-family: Changa One;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 34px;
      text-align: left;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 20px;
  
      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 51px;
      }
    }
  
    p {
      color: #747474;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 0;
  
      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
`

export default IntroText
