import React from 'react'
import styled from '@emotion/styled'
import {
  Col,
  Container,
  Row
} from 'react-bootstrap'

import { TabDataContent } from './models'

import AccordionBox from './components/AccordionBox'
import TabBox from './components/TabsBox'

const Tabs = styled(({ className }) => {
  const title = TabDataContent()[0]
  const data = TabDataContent()[1]

  return (
    <div className={className}>
      <Container>
        <Row>
          <Col md="12" xl="6">
            <div className="section-title">
              <div className="indexTitle">01</div>
              <div className="title">
                <span className="h2" dangerouslySetInnerHTML={{ __html: title }}></span>
              </div>
            </div>
          </Col>
          <Col md="12" xl="12">
            <div className="d-xl-block d-none">
              <TabBox data={data}/>
            </div>
            <div className="d-xl-none">
              <AccordionBox data={data}/>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  background: #f4f7f6;
  padding-top: 45px;

  .section-title {

    @media (max-width: 768px) {
      padding: 0 15px;
    }

    @media only screen and (min-width: 670px) and (max-width: 769px) {
      padding: 0 64px 0 59px;
    }

    @media (min-width: 1200px) {
      padding: 0 0;
    }
  }

  .container{
  
    @media (max-width: 768px) {
      padding: 0 0;
    }

    @media only screen and (min-width: 670px) and (max-width: 769px)  {
      max-width: none;
    }
  }

  .indexTitle {
    position: absolute;
    top: -70px;
  }

  .title {
    margin-top: 0;

    .h2 {
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }
`

export default Tabs
