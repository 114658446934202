import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

import { GalleryDataContent } from './models'

import ThemeContext from '@contexts/themeContext'

import PopupGallery from './components/PopupGallery'

const IntroText = styled(({ className }) => {
  const { activeGalleryPopup, togglePopupGallery } = useContext(ThemeContext)

  const title = GalleryDataContent()[0]
  const gallery = GalleryDataContent()[1]

  return (
    <div className={`${className}`}>
      <PopupGallery data={gallery} />
      <div className="gallery-area">
        <Container>
          <Row>
            <Col md="12" xl="3">
              <div className="indexTitle">02</div>
              <div className="title">
                <span
                  className="h2"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              </div>
            </Col>
            <Col md="12" xl="9" className="pl-xl-5-5">
              <Row>
                <Col md="6" className="pr-xl-0">
                  <button
                    onClick={() => togglePopupGallery(!activeGalleryPopup, 0)}
                  >
                    <BackgroundImage
                      className="image big"
                      fluid={gallery[0].node.childImageSharp.fluid}
                    >
                      <span></span>
                    </BackgroundImage>
                  </button>
                </Col>
                <Col md="6" className="pl-md-0">
                  <Row className="m-xl-0">
                    {gallery.map((item, index) => {
                      if (index !== 0 && index < 5) {
                        return (
                          <Col
                            xs="6"
                            md="6"
                            className={`${
                              (index - 1) % 2 === 1 ? 'pl-0' : 'pr-0'
                            }`}
                            key={index}
                          >
                            <button
                              onClick={() =>
                                togglePopupGallery(!activeGalleryPopup, index)
                              }
                            >
                              <BackgroundImage
                                className={`${
                                  index % 2 === 0 ? 'ml-auto' : null
                                } image normal`}
                                fluid={item.node.childImageSharp.fluid}
                              >
                                <span></span>
                              </BackgroundImage>
                            </button>
                          </Col>
                        )
                      } else {
                        return false
                      }
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  background: #f4f7f6;
  padding-top: 60px;

  @media (min-width: 1200px) {
    padding-top: 120px;
  }

  .container {
    position: relative;
  }

  .title {
    .h2 {
      margin-bottom: 30px;

      @media (max-width: 400px) {
        width: 75%;
      }

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }

    @media (min-width: 1200px) {
      padding-right: 25px;
    }
  }

  button {
    background: transparent;
    border: none;
    display: block;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;

    span {
      bottom: -6px;
      border: 3px solid #63a536;
      content: '';
      display: none;
      left: -6px;
      top: -6px;
      transition: all 0.5s ease;
      right: -6px;
      position: absolute;
    }

    &:hover {
      span {
        display: block;
      }
    }
  }

  .gallery-area {
    .image {
      background-size: cover;
      background-position: center center;
      margin-bottom: 20px;
      position: relative;
      width: 100%;

      &.big {
        height: 345px;

        @media (min-width: 768px) {
          height: 325px;
        }

        @media (min-width: 1200px) {
          height: 400px;
          margin-left: auto;
          margin-right: 5px;
          width: calc(100% - 5px);
        }
      }

      &.normal {
        height: 164px;
        width: calc(100% - 8px);

        @media (min-width: 768px) {
          height: 152px;
        }

        @media (min-width: 1200px) {
          height: 190px;
          width: calc(100% - 10px);
        }
      }
    }
  }
`

export default IntroText
