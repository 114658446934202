import { useStaticQuery, graphql } from 'gatsby'

export const ServiceBlockDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allServiceBlockDataJson, images } = useStaticQuery(
    graphql`
      query queryServiceBlockDataContent {
        allServiceBlockDataJson {
          edges {
            node {
              title
              services {
                title
                summary
                content {
                  text
                  sublist{
                    text
                  }
                }
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "services" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 500, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allServiceBlockDataJson.edges[0].node.title,
    allServiceBlockDataJson.edges[0].node.services,
    images.edges
  ]
}
