import React from 'react'
import styled from '@emotion/styled'

// components
import SEO from './components/Seo'
import Hero from './components/Hero'
import Certified from './components/Certified'
import IntroText from './components/Intro-Text'
import SectionArea from './components/SectionArea'
import ServiceBlock from './components/ServiceBlock'
import SliderBlock from './components/SliderBlock'
import SecondText from './components/Second-Text'
import Tabs from './components/Tabs'
import FormArea from './components/FormArea'
import Popup from './components/Popup'

const Home = styled(({ className }) => {
  return (
    <div className={className}>
      <SEO />
      <Hero />
      <Certified />
      <IntroText />
      <SectionArea />
      <ServiceBlock />
      <SliderBlock />
      <SecondText />
      <Tabs />
      <FormArea />
      <Popup />
    </div>
  )
})`
  margin: 129px auto 0;
  overflow: hidden;

  @media (min-width: 768px) {
    margin: 145px auto 0;
  }

  @media (min-width: 1200px) {
    margin: 160px auto 0;
  }
`

export default Home
