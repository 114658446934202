import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Accordion, Card, Button, Container, Row, Col } from 'react-bootstrap'
import scrollTo from 'gatsby-plugin-smoothscroll'

import { AccordionDataContent } from './models'

const Accordions = styled(({ className }) => {
  const title = AccordionDataContent()[0]
  const items = AccordionDataContent()[1]
  const bottom = AccordionDataContent()[2]

  const [currentActiveKey, setCurrentActiveKey] = useState(null)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key ? null : key)
  }

  return (
    <div className={`${className}`}>
      <Container>
        <Row>
          <Col md="12" xl="3">
            <div className="indexTitle">04</div>
            <div className="title">
              <span className="h2" dangerouslySetInnerHTML={{ __html: title }}></span>
            </div>
          </Col>
          <Col md="12" xl="9" className="pl-xl-5-5">
            <Accordion defaultActiveKey="0">
              {items.map((item, index) => {
                return (
                  <Card key={index}>
                    <Card.Header
                      className={currentActiveKey === index ? 'active' : null}
                    >
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={index + 1}
                        onClick={() => toggleActiveKey(index)}
                      >
                        <div className="item-title">{item.title}</div>
                        <div className="span" dangerouslySetInnerHTML={{ __html: item.subtitle }}></div>
                        <i></i>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index + 1}>
                      <Card.Body>
                        <ul data-count={item.content.length}>
                          {item.content.map((li, index) => {
                            return <li key={index} dangerouslySetInnerHTML={{ __html: li.text }}></li>
                          })}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </Col>
          <Col md="12">
            <div className="bottom-area">
              <p>{bottom.bottom_text}</p>
              <button onClick={() => { document.getElementsByClassName("embed-button")[0].click() }}>
                {bottom.bottom_cta}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  background: #f4f7f6;
  padding-top: 60px;

  @media (min-width: 1200px) {
    padding-top: 120px;
  }

  .container {
    position: relative;
  }

  .title {
    margin-bottom: 30px;
  }

  .accordion {
    .card {
      margin-bottom: 10px;

      @media (min-width: 768px) {
        margin-bottom: 20px;

        &:last-child{
          .card-header button{
            .span {
              padding-bottom: 16px;
              padding-top: 16px;
            }
          }
        }
      }

      .card-header {
        background: #ffffff;
        position: relative;

        /*@media (max-width: 576px) {
          border-left: 5px solid transparent;
        }*/

        i {
          background: #63a536;
          border: 2px solid #63a536;
          border-radius: 50%;
          display: block;
          height: 40px;
          right: 20px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          transition: 0.3s all ease-in;

          &:before,
          &:after {
            background: #ecf6e4;
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: 0.3s all ease-in;
          }

          &:before {
            width: 2px;
            height: 24px;
          }

          &:after {
            height: 2px;
            width: 24px;
          }
        }

        &.active {

          button {

            .item-title {
              color: #231e60;
            }

            i {
              background: #fff;

              &:before,
              &:after {
                background: #63a536;
                transform: translate(-50%, -50%) rotate(45deg);
              }
            }
          }

          @media (min-width: 768px) {
            button {
              .item-title {
                background: #231e60;
                color: #fff;
              }

              .span {
                font-weight: bold;
              }
            }
          }

          @media (max-width: 576px) {

            button {
              color: #141414;
              font-weight: bold;

              .item-title {
                color: #231E60;
              }
            }
          }
        }

        button {
          color: #141414;
          font-family: Titillium Web;
          font-style: normal;
          font-weight: normal;
          font-size: 16px !important;
          line-height: 24px;
          height: auto !important;
          padding: 15px 15px 15px 15px !important;
          text-align: left;
          transition: 0.3s all ease-in;
          width: 100%;

          .item-title {
            color: #63a536;
            display: block;
            font-weight: bold;
            transition: 0.3s all ease-in;

            @media (min-width: 768px) {
              background: #ecf6e4;
              color: #141414;
              display: flex;
              align-items: center;
              font-weight: 400;
              padding: 20px 40px;
            }
          }

          .span{
            transition: 0.3s all ease-in;
            span{
              color: #747474;
              display: block;
              font-size: 12px;
              line-height: 20px;
            }
          }

          @media (min-width: 768px) {
            align-items: stretch;
            display: flex;
            padding: 0 0 0 0 !important;

            .item-title, .span{
              font-size: 24px;
              line-height: 36px;
            }

            .span{
              padding: 20px 26px;
              span{
                font-size: 16px;
                line-height: 24px;
                font-weight: 400 !important;
              }
            }

            &:hover {
              .span {
                font-weight: bold;
                span{
                  color: rgb(20,20,20);
                }
              }
            }
          }

          @media (min-width: 1250px) {
            .span{
              span{
                display: inline-block;
                font-size: 24px;
                line-height: 36px;
              }
            }
          }

          &:focus,
          &:hover {
            box-shadow: none;
            outline: none;
            text-decoration: none;
          }
        }
      }

      .card-body {
        color: #747474;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        padding-top: 20px;

        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          list-style-type: none;
          justify-content: space-between;
          padding: 0 20px;

          &[data-count="1"]{
            li{
              border: none;
              flex: 0 0 calc(100% - 10px);
              max-width: calc(100% - 10px);
            }
          }

          li {
            border-bottom: 1px solid rgba(166, 166, 166, 0.3);
            color: #141414;
            font-size: 16px;
            line-height: 24px;
            position: relative;
            padding: 15px 0;
            width: 100%;

            @media (max-width: 576px) {
              &:last-child{
                border: none;
              }
            }
          }
        }

        @media (min-width: 768px) {
          padding: 20px 0;

          ul {
            li {
              flex: 0 0 calc(50% - 10px);
              padding: 15px 20px;
              max-width: calc(50% - 10px);
            }
          }
        }

        @media (min-width: 1200px) {
          padding: 20px 79px;
        }

        /*@media (max-width: 576px) {
          background: #ffffff;
          border-left: 5px solid #63a536;
          font-size: 16px;
          line-height: 24px;
          padding: 15px;

          ul {
            li {
              padding: 5px 10px;

              &:before {
                background: #a1d078;
                content: '';
                height: 7px;
                left: -16px;
                position: absolute;
                top: 50%;
                transform: translateY(-505);
                width: 7px;
              }
            }
          }
        }*/
      }
    }
  }

  .bottom-area {
    padding: 40px 0 0;

    @media (min-width: 768px) {
      padding-top: 60px;
    }

    @media (min-width: 1200px) {
      padding-top: 100px;
    }

    p {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    button {
      background: #63a536;
      border-radius: 4px;
      border: none;
      color: #fff;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      padding: 16px 48px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.25s;
      margin: 40px auto 80px;

      @media (min-width: 1200px) {
        margin-bottom: 115px;
      }

      &:hover,
      &:focus {
        box-shadow: inset 0 -5em 0 0 #231e60;
        color: #fff;
        text-decoration: none;
      }
    }
  }
`

export default Accordions
