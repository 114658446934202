import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'

import { IntroTextDataContent } from './models'

const IntroText = styled(({ className }) => {
  const title = IntroTextDataContent()[0]
  const subtitle = IntroTextDataContent()[1]
  const button_cta = IntroTextDataContent()[2]

  return (
    <div className={`${className}`}>
      <Container className="text-xl-center">
        <span className="h1" dangerouslySetInnerHTML={{ __html: title }}></span>
        <p>{subtitle}</p>
        <button onClick={() => { document.getElementsByClassName("embed-button")[0].click() }}>
          {button_cta}
        </button>
      </Container>
    </div>
  )
})`
  margin-top: 60px;

  @media (min-width: 768px) {
    margin-top: 80px;
  }

  .h1 {
    color: #231e60;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    text-align: left;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 51px;
      letter-spacing: 0.02em;
    }

    @media (min-width: 1200px) {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  p {
    color: #141414;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 30px;
    }
  }

  button {
    background: #63a536;
    border-radius: 4px;
    border: none;
    color: #fff;
    display: block;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    padding: 16px 48px;
    text-align: center;
    transition: 0.25s;
    margin-bottom: 65px;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 768px) {
      margin-bottom: 83px;
      width: auto;
    }

    @media (min-width: 1200px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 85px;
    }

    &:hover,
    &:focus {
      box-shadow: inset 0 -5em 0 0 #231e60;
      color: #fff;
      text-decoration: none;
    }
  }
`

export default IntroText
