import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

import { ServiceBlockDataContent } from './models'

const ServiceBlock = styled(({ className }) => {
  const title = ServiceBlockDataContent()[0]
  const services = ServiceBlockDataContent()[1]
  const images = ServiceBlockDataContent()[2]

  const [currentActiveKey, setCurrentActiveKey] = useState(null)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key ? null : key)
  }

  return (
    <div className={`${className}`}>
      <div id="services" className="scroll-target"></div>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        {services.map((item, index) => {
          return (
            <div className={`service service-${index}`} key={index}>
              <Row>
                <Col
                  xs="5"
                  xl="5"
                  md={{ span: 6, order: index % 2 === 0 ? 0 : 1 }}
                >
                  <BackgroundImage
                    className={`image image-${index}`}
                    fluid={images[index].node.childImageSharp.fluid}>
                    <span></span>
                  </BackgroundImage>
                </Col>
                <Col
                  xs="7"
                  xl="7"
                  md={{ span: 6, order: index % 2 === 0 ? 1 : 0 }}
                  className="pl-0 pl-xl-3 pr-xl-3 p-md-0"
                >
                  <div className="service-content">
                    <div className="title">{item.title}</div>
                    <p className="summary d-none d-md-block">{item.summary}</p>
                    <div className="d-none d-xl-block">
                      <div
                        className={
                          'text ' + (currentActiveKey === index ? 'active' : '')
                        }
                      >
                        <ul>
                          {item.content.map((li, index) => {
                            return <li key={index}>
                              {li.text}
                              {li.sublist &&
                                <ul>
                                  {li.sublist.map((li, index) => {
                                    return <li key={index}>{li.text}</li>
                                  })}
                                </ul>
                              }
                            </li>
                          })}
                        </ul>
                      </div>
                      <button
                        className={
                          'readMore ' + (currentActiveKey === index ? 'active' : '')
                        }
                        onClick={() => toggleActiveKey(index)}
                      >
                        <div className="more">Read More</div>
                        <div className="less">Read Less</div>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md={{ span: 12, order: 3 }} className="d-xl-none">
                  <div
                    className={
                      'text ' + (currentActiveKey === index ? 'active' : '')
                    }
                  >
                    <p className="d-md-none">{item.summary}</p>
                    <ul>
                      {item.content.map((li, index) => {
                        return <li key={index}>
                          {li.text}
                          {li.sublist &&
                            <ul>
                              {li.sublist.map((li, index) => {
                                return <li key={index}>{li.text}</li>
                              })}
                            </ul>
                          }
                        </li>
                      })}
                    </ul>
                  </div>
                  <button
                    className={
                      'readMore ' + (currentActiveKey === index ? 'active' : '')
                    }
                    onClick={() => toggleActiveKey(index)}
                  >
                    <div className="more">Read More</div>
                    <div className="less">Read Less</div>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
          )
        })}
      </Container>
    </div>
  )
})`
  background: #231e60;
  padding: 60px 0;
  position: relative;

  @media (min-width: 1200px) {
    padding: 80px 0 100px;
  }

  .scroll-target {
    position: absolute;
    top: -120px;
  }

  .container {
    position: relative;
  }

  h2 {
    color: #ffffff;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;

    @media (min-width: 1200px) {
      font-size: 48px;
      line-height: 51px;
      margin-bottom: 60px;
      width: 100%;
    }
  }

  .service {
    margin-bottom: 60px;

    &.service-0{
      @media (min-width: 768px) {
        .text { height: 200px; }
      }
      @media (min-width: 1200px) {
        .text { height: 254px; }
        .service-content{ padding-right: 55px; }
      }
    }

    &.service-1{
      @media (min-width: 768px) {
        .text { height: 140px; }
      }
      @media (min-width: 1200px) {
        margin-bottom: 80px;
        .text { height: 126px; }
        .service-content{ padding-left: 29px; }
      }
    }

    &.service-2{
      @media (min-width: 768px) {
        .text { height: 172px; }
        .image{ height: 283px; }
      }
      @media (min-width: 1200px) {
        .image{ height: 395px; }
        .text { height: 153px; }
        .service-content{ padding-right: 29px; }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .image {
      background-position: center center;
      background-size: cover;
      display: block;
      height: 100px;
      margin-bottom: 30px;
      position: relative;
      width: 100px;

      span{
        background-color: #a1d078;
        bottom: -10px;
        height: 100%;
        position: absolute;
        right: -10px;
        width: 100%;
        z-index: -200;
      }

      @media (min-width: 550px) {
        height: 180px;
        width: 180px;
      }

      @media (min-width: 768px) {
        height: 252px;
        margin-bottom: 60px;
        width: 252px;

        span{
          bottom: -20px;
          right: -20px;
        }
      }

      @media (min-width: 1200px) {
        height: 392px;
        margin-bottom: 0;
        width: 350px;
      }
    }

    .service-content{
      margin-left: -11px;

      @media (min-width: 768px) {
        margin-left: 0;
      }
    }

    .title {
      color: #ffffff;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      padding-right: 5px;
      margin-top: 18px;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-top: 0;
      }

      @media (min-width: 1200px) {        
        padding-right: 20px;
      }
    }

    .summary {
      color: #ffffff;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
      margin-top: 20px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 32px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    .text {
      color: #ffffff;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      height: 372px;
      overflow: hidden;
      position: relative;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 32px;
        height: 111px;
      }

      &:before {
        background: linear-gradient(
          180deg,
          rgba(35, 30, 96, 0) 39.51%,
          rgba(35, 30, 96, 0.8) 100%
        );
        bottom: 0;
        content: '';
        height: 128px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
      }

      &.active {
        height: unset;

        &:before {
          opacity: 0;
        }
      }

      p {
        margin-bottom: 20px;
      }

      ul {
        list-style-type: none;
        padding: 0 0 0 23px;
        margin: 0;

        li {
          position: relative;
          margin-bottom: 22px;

          ul{
            margin-top: 15px;
          }

          @media (min-width: 1200px) {
            margin-bottom: 15px;
          }

          &:before {
            background: #a1d078;
            content: '';
            height: 7px;
            left: -23px;
            position: absolute;
            top: 12px;
            width: 7px;
          }
        }
      }
    }

    .readMore {
      background-color: transparent;
      border: none;
      color: #ffffff;
      display: inline-block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding-right: 24px;
      position: relative;
      text-align: left;

      .less{ display: none; }

      &.active{
        .less{ display: block; }
        .more{ display: none; }
      }

      span {
        display: block;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -8px;
        width: 24px;

        &:before,
        &:after {
          background: #63a536;
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          width: 3px;
          height: 15px;
        }

        &:after {
          height: 3px;
          width: 15px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .service-1{
      .service-content {
        padding-left: 15px;
      }
    }
    .order-md-1 {
      .image {
        margin-left: auto;
      }
    }
  }

  .service-content {
    @media (min-width: 1200px) {
      padding-right: 7%;
    }
  }
`

export default ServiceBlock
