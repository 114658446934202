import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Col, Tab, Nav, Row } from 'react-bootstrap'
import { array } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const TabsBox = styled(({ data, className }) => {

  const { activeTab, toggleTab } = useContext(ThemeContext)

  return (
    <div className={className}>
      <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} activeKey={activeTab}>
        <Row>
          <Col className="pr-0 col-xl-5-5">
            <Nav variant="pills" className="flex-column">
              {data.map((item, index) => {
                return (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index} onClick={()=> toggleTab(index)} className={ (activeTab !== 0 && (activeTab-1) === index) ? 'prevActive' : null }>
                      <span>{item.title}</span>
                    </Nav.Link>
                  </Nav.Item>
                )
              })}
            </Nav>
          </Col>
          <Col className="pl-0 col-xl-6-5">
            <Tab.Content>
              {data.map((item, index) => {
                return (
                  <Tab.Pane eventKey={index} key={index}>
                    {item.content_type === 'text' && (
                      <p>{item.content}</p>
                    )}

                    {item.content_type === 'list' && (
                      <ul>
                        {item.list.map((li, index) => {
                          return <li key={index}>
                            {li.text}
                            {li.sublist &&
                              <ul>
                                {li.sublist.map((li, index) => {
                                  return <li key={index}>{li.text}</li>
                                })}
                              </ul>
                            }
                          </li>
                        })}
                      </ul>
                    )}
                  </Tab.Pane>
                )
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
})`
  .fade {
    display: none;

    &.active {
      display: block;
    }
  }

  .nav {
    margin-left: -30px;

    .nav-item {

      &:first-of-type{
        a{
          margin-top: 0 !important;
        }
      }

      &:last-child{
        a{
          margin-bottom: 0 !important;

          &:not(.active){
            span{
            }
          }

          span{
            &:before{
              display: none;
            }
          }
        }
      }

      a {
        border-left: 10px solid transparent;
        color: #141414;
        display: block;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        padding: 0 35px 0 40px;
        text-decoration: none;

        &.prevActive{
          span:before{
            display: none;
          }
        }

        span {
          display: block;
          padding: 23px 0 23px 0;
          position: relative;

          &:before {
            content: '';
            background: #231e60;
            bottom: 0;
            height: 0.91px;
            opacity: 0.2;
            left: 13px;
            position: absolute;
            right: 13px;
          }
        }

        &.active {
          background-color: #fff;
          border-left-color: #a1d078;
          font-weight: bold;
          margin: 27px 0;

          span {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .tab-content {
    background-color: #fff;
    height: 100%;
    padding: 50px 30px 40px;
    position: relative;

    @media (min-width: 1200px) {
      &:after {
        background-color: #fff;
        bottom: 0;
        content: '';
        position: absolute;
        right: -100%;
        top: 0;
        width: 100%;
      }
    }

    p {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      position: relative;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      padding: 0 0 0 23px;
      margin: 0;

      li {
        color: #141414;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        position: relative;
        margin-bottom: 10px;

        ul{
          margin-top: 10px;
        }

        &:before {
          background: #a1d078;
          content: '';
          height: 7px;
          left: -23px;
          position: absolute;
          top: 12px;
          width: 7px;
        }
      }
    }
  }
`

TabsBox.propTypes = {
  data: array.isRequired
}

TabsBox.defaultProps = {
  data: {}
}

export default TabsBox
