import React from 'react'
// compositions
import Layout from '@compositions/Layout'
import Home from '@compositions/Home'

import ThemeProvider from '@providers/themeProvider'
const HomePage = () => {
  return (
    <ThemeProvider>
      <Layout>
        <Home />
      </Layout>
    </ThemeProvider>
  )
}
export default HomePage
