import { useStaticQuery, graphql } from 'gatsby'

export const CertifiedDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCertifiedDataJson, images } = useStaticQuery(
    graphql`
      query queryCertifiedDataContent {
        allCertifiedDataJson {
          edges {
            node {
              title
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "logos" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 500, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allCertifiedDataJson.edges[0].node.title,
    images.edges
  ]
}
