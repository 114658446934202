import { useStaticQuery, graphql } from 'gatsby'

export const AccordionDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allAccordionDataJson } = useStaticQuery(
    graphql`
      query queryAccordionDataContent {
        allAccordionDataJson {
          edges {
            node {
              title
              items {
                title
                subtitle
                content {
                  text
                }
              }
              bottom {
                bottom_text
                bottom_cta
              }
            }
          }
        }
      }
    `
  )
  return [
    allAccordionDataJson.edges[0].node.title,
    allAccordionDataJson.edges[0].node.items,
    allAccordionDataJson.edges[0].node.bottom
  ]
}
