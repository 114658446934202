import React from 'react'
import styled from '@emotion/styled'

// components
import Tabs from './components/Tabs'
import Gallery from './components/Gallery'
import Card from './components/Card'
import Accordions from './components/Accordions'

const SectionArea = styled(({ className }) => {
  return (
    <div className={className}>
      <div id="covid-19-disinfection" className="scroll-target"></div>
      <Tabs />
      <Gallery />
      <Card />
      <Accordions />
    </div>
  )
})`
  position: relative;

  .scroll-target {
    position: absolute;
    top: -120px;
  }
`

export default SectionArea
