import { useStaticQuery, graphql } from 'gatsby'

export const TabDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allTabDataJson } = useStaticQuery(
    graphql`
      query queryTabDataContent {
        allTabDataJson {
          edges {
            node {
              title
              tabs {
                title
                content
                content_type
                list {
                  text
                  sublist{
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return [allTabDataJson.edges[0].node.title, allTabDataJson.edges[0].node.tabs]
}
