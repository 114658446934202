import { useStaticQuery, graphql } from 'gatsby'

export const FormAreaDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allFormAreaDataJson } = useStaticQuery(
    graphql`
      query queryFormAreaDataContent {
        allFormAreaDataJson {
          edges {
            node {
              form {
                title
                content
              }
            }
          }
        }
      }
    `
  )
  return [allFormAreaDataJson.edges[0].node.form]
}
