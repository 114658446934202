import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { Accordion, Card, Button } from 'react-bootstrap'
import { array } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const AccordionBox = styled(({ data, className }) => {
  const { activeTab, toggleTab } = useContext(ThemeContext)

  const [currentActiveKey, setCurrentActiveKey] = useState(1)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key)
  }

  const [CurrentActive, setCurrentActive] = useState(true)

  const toggleActive = (key) => {
    setCurrentActive(!CurrentActive)
    if(key !== activeTab){
      setCurrentActive(true)
      toggleTab(key)
      setCurrentActiveKey(currentActiveKey === key)
    }
  }

  return (
    <div className={className}>
      <Accordion defaultActiveKey={activeTab} activeKey={ CurrentActive === true ? activeTab + 1 : currentActiveKey }>
        {data.map((item, index) => {
          return (
            <Card key={index} className={ (activeTab !== 0 && (activeTab-1) === index) && (CurrentActive === true) ? 'prevActive' : null }>
              <Card.Header className={(activeTab === index) && (CurrentActive === true) ? 'active' : null}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={index+1}
                  onClick={() => {
                    toggleActive(index)
                    toggleActiveKey(index+1)
                    toggleTab(index)
                  }}
                >
                  <span>{item.title}</span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index+1}>
                <Card.Body>
                  {item.content_type === 'text' && <p>{item.content}</p>}

                  {item.content_type === 'list' && (
                    <ul>
                      {item.list.map((li, index) => {
                        return <li key={index}>
                          {li.text}
                          {li.sublist &&
                            <ul>
                              {li.sublist.map((li, index) => {
                                return <li key={index}>{li.text}</li>
                              })}
                            </ul>
                          }
                        </li>
                      })}
                    </ul>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    </div>
  )
})`
  .accordion {
    .card {

      &.prevActive{
        .card-header button span{
          border: none;
        }
      }

      &:last-child {
        border: none;

        .card-header {
          button {
            span {
              border: none;
            }
          }
        }
      }

      .card-header {
        border-left: 5px solid transparent;
        position: relative;

        &.active {
          background-color: #fff;
          border-left: 5px solid #63a536;

          button {
            font-weight: bold;

            &:before {
              opacity: 0;
            }

            span {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }

        button {
          border: none;
          color: #141414;
          font-family: Titillium Web;
          font-style: normal;
          font-weight: normal;
          font-size: 20px !important;
          height: auto !important;
          line-height: 32px;
          text-align: left;
          padding: 0 59px 0 10px !important;
          position: relative;
          width: 100%;

          &:before,
          &:after {
            background: #63a536;
            content: '';
            position: absolute;
          }

          &:before {
            height: 24px;
            right: 26px;
            width: 2px;
            top: 50%;
            transform: translateY(-50%);
          }

          &:after {
            height: 2px;
            right: 15px;
            width: 24px;
            top: 50%;
            transform: translateY(-50%);
          }

          span {
            border-bottom: 1px solid rgba(25, 25, 112, 0.2);
            display: block;
            padding: 20px 0;
            width: 100%;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            text-decoration: none;
            outline: none;
          }
        }

        @media (min-width: 670px) {
          padding: 0 64px 0 59px !important;

          &:before {
            right: 11px;
          }

          &:after {
            right: 0;
          }

          button {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }

          @media (min-width: 769px) {
            button {
              padding: 0 5px !important;
            }
          }
        }
      }

      .card-body {
        border-left: 5px solid #63a536;
        background: #ffffff;
        color: #141414;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        padding: 20px 15px 20px 38px;

        ul {
          padding: 0;
          margin: 0;

          li {
            margin-bottom: 15px;
            position: relative;
            list-style-type: none;

            ul{
              margin-top: 15px;
            }

            &:before {
              background: #a1d078;
              content: '';
              display: block;
              height: 7px;
              left: -16px;
              top: 12px;
              position: absolute;
              width: 7px;
            }
          }
        }

        @media (min-width: 768px) {
          padding: 20px 64px 24px 64px !important;

          ul {
            padding-left: 16px;
          }
        }
      }
    }
  }

  .fade {
    display: none;

    &.active {
      display: block;
    }
  }
`

AccordionBox.propTypes = {
  data: array.isRequired
}

AccordionBox.defaultProps = {
  data: {}
}

export default AccordionBox
