import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import scrollTo from 'gatsby-plugin-smoothscroll'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick'

import { SliderDataContent } from './models'

import SliderArrowPrev from './components/SliderArrowPrev'
import SliderArrowNext from './components/SliderArrowNext'

const SliderBlock = styled(({ className }) => {
  
  const slider = useRef()
  
  const card = SliderDataContent()[0]
  const sliders = SliderDataContent()[1]
  const images = SliderDataContent()[2]
  const sliderBg = SliderDataContent()[3]

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1.703,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.1,
          arrows: false,
          dots: true
        }
      }
    ],
    beforeChange: (current, next) => toggleCurrentKey(current.toFixed()),
    afterChange: (current) => toggleCurrentKey(current.toFixed())
  }

  const [currentActiveKey, setCurrentActiveKey] = useState(null)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key ? null : key)
  }

  const [currentKey, setCurrentKey] = useState(1)

  const toggleCurrentKey = (key) => {
    setCurrentKey(currentKey === key ? 1 : parseInt(key) + 1)
  }

  const onNext = () => {
    slider.current.slickNext();
  };
  const onPrev = () => {
    slider.current.slickPrev();
  };

  return (
    <div className={`${className}`}>
      <BackgroundImage className="bg" fluid={sliderBg.childImageSharp.fluid} />
      <Container className="container-fluid-right">
        <Row>
          <Col xs="12" xl="12" className="card-area">
            <div className="card">
              <span
                className="h1"
                dangerouslySetInnerHTML={{ __html: card.title }}
              ></span>
              <p>{card.text}</p>
              <button className="cta" onClick={() => { document.getElementsByClassName("embed-button")[0].click() }}>
                {card.cta}
              </button>
              
              <div className="arrow d-none">
                <SliderArrowPrev onClick={onPrev} className={`${ currentKey === 1 ? 'disable' : null}`} />
                <SliderArrowNext onClick={onNext} className={`${ currentKey === sliders.length-1 ? 'disable' : null}`} />
                <div className="currentIndex">
                  {currentKey} / {sliders.length - 1}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" xl="12" className="pr-0 pr-xl-3">
            <Slider ref={slider} {...settings}>
              {sliders.map((item, index) => {
                return (
                  <div className={`position-relative w-100`} key={index}>
                    <div className={`sliderItem sliderItem-${index}`}>
                      <div className="toptitle">{item.toptitle}</div>
                      <BackgroundImage
                        className="image"
                        fluid={images[index].node.childImageSharp.fluid}
                      />
                      <span className="h2">{item.title}</span>
                      <div
                        className={
                          'text ' + (currentActiveKey === index ? 'active' : '')
                        }
                      >
                        <ul>
                          {item.content.map((li, index) => {
                            return(
                              <li key={index}>
                                {li.text}
                                {li.sublist &&
                                  <ul>
                                    {li.sublist.map((li, index) => {
                                      return <li key={index}>{li.text}</li>
                                    })}
                                  </ul>
                                }
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <button
                        className={
                          'readMore ' +
                          (currentActiveKey === index ? 'active' : '')
                        }
                        onClick={() => toggleActiveKey(index)}
                      >
                        <div className="more">Read More</div>
                        <div className="less">Read Less</div>
                        <span></span>
                      </button>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  padding-top: 60px;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 80px;
  }

  .container {
  }

  .bg {
    background-size: cover;
    background-position: center center;
    //height: calc(100% - 155px);
    height: 791px;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;

    @media (min-width: 768px) {
      height: 709px;
    }

    @media (min-width: 1200px) {
      height: 604px;
    }

    @media (min-width: 1400px) {
      height: 663px;
    }
  }

  .card-area{
    position: relative;
    
    .arrow{
      bottom: -142px;
      display: none;
      position: absolute;
      right: 29px;

      @media (min-width: 1200px) {
        display: block;
      }

      .currentIndex {
        color: #fff;
        display: none;
        font-family: Titillium Web;
        font-size: 16px;
        line-height: 24px;
        right: 0;
        position: relative;
        margin-top: 24px;

        @media (min-width: 768px) {
          display: block;
        }
      }

      button {
        background-color: transparent;
        border: none;
        display: block;
        padding: 0;
        margin: 0 auto;
        z-index: 1;

        &:first-of-type{
          margin-bottom: 13px;
        }
  
        &.disable {
          opacity: 0.2;
        }
  
        img {
          filter: brightness(10);
          height: 24px;
          transition: all 0.2s ease-out;
          width: 24px;
        }
  
        &:focus {
          outline: none;
        }
  
        &:hover {
          outline: none;
  
          img {
            filter: none;
          }
        }
      }
    }

    .card {
      padding: 0 20px;
      margin-bottom: 60px;
      position: relative;
      text-align: center;

      .h1 {
        color: #ffffff;
        font-family: Changa One;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 34px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      p {
        color: #ffffff;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 30px;
      }

      button.cta {
        background: #63a536;
        border-radius: 4px;
        border: none;
        color: #fff;
        display: block;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        padding: 16px 48px;
        text-align: center;
        transition: 0.25s;
        text-transform: uppercase;
        width: 100%;

        @media (min-width: 768px) {
          padding: 16px 62px;
          margin: 0 auto;
          width: auto;
        }

        @media (min-width: 1200px) {
        }

        &:hover,
        &:focus {
          box-shadow: inset 0 -5em 0 0 #fff;
          color: #63a536;
          text-decoration: none;
        }
      }
    }
  }

  .slick-slide {
    margin: 0 20px 0 0 !important;

    > div {
      width: 100%;
    }
  }

  .slick-list {
    @media (min-width: 768px) {
      overflow: visible;
    }

    @media (min-width: 1200px) {
      overflow: hidden;
    }
  }

  .slick-slider {

    @media (min-width: 1200px) {
    }

    .slick-dots {
      justify-content: center;
      margin-top: 62px !important;
      margin-bottom: 0;

      @media (min-width: 768px) {
        margin-top: 70px !important;
      }

      li {
        button {
          background: #231e60;
          height: 10px;
          opacity: 0.4;
          width: 10px;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  .sliderItem {
    background: #ffffff;
    padding: 14px 30px 0;

    @media (min-width: 768px) {
      padding-top: 30px;
    }

    &.sliderItem-2{
      .text{
        margin-bottom: 3px;

        ul{
          li{
            &:nth-of-type(1n+4){
              display: none;
            }
          }
        }

        @media (min-width: 1200px) {
          margin-bottom: 20px;
        }
      }
    }

    .toptitle {
      color: #747474;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .image {
      display: block;
      height: 150px;
      background-size: cover;
      background-position: center center;
      margin-bottom: 10px;
    }

    .h2 {
      color: #141414;
      font-family: Titillium Web !important;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    .text {
      display: block;
      overflow: hidden;
      margin-bottom: 39px;

      @media (min-width: 1200px) {
        margin-bottom: 57px;
      }

      ul {
        padding: 0 0 0 20px;
        margin-bottom: 0;

        li {
          color: #141414;
          font-family: Titillium Web;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          list-style-type: none;
          position: relative;
          margin-bottom: 10px;

          &:nth-of-type(1n+9){
            display: none;
          }

          ul{
            margin-top: 5px;
            padding-left: 20px !important;

            li{

              &:before{
                background: #63A536;
                height: 2px !important;
                left: -20px !important;
                top: 15px !important;
                width: 10px !important;
              }
            }
          }

          &:before {
            background: #a1d078;
            content: '';
            height: 5px;
            left: -20px;
            position: absolute;
            top: 11px;
            width: 5px;
          }
        }
      }

      &.active {
        ul{
          li{
            display: block !important;
          }
        }
      }
    }

    .readMore {
      background-color: transparent;
      border: none;
      color: #141414;
      display: inline-block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding-right: 24px;
      position: relative;
      text-align: left;

      .less {
        display: none;
      }

      &.active {
        .less {
          display: block;
        }
        .more {
          display: none;
        }
      }

      &:focus,
      &:hover {
        outline: none;
      }

      span {
        display: block;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -8px;
        width: 24px;

        &:before,
        &:after {
          background: #63a536;
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          width: 3px;
          height: 15px;
        }

        &:after {
          height: 3px;
          width: 15px;
        }
      }
    }
  }
`

export default SliderBlock
