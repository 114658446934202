import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'

import { CardDataContent } from './models'

const Card = styled(({ className }) => {
  const title = CardDataContent()[0]
  const cards = CardDataContent()[1]
  const icons = CardDataContent()[2]

  return (
    <div className={`${className}`}>
      <Container>
        <Row>
          <Col md="12" xl="3">
            <div className="indexTitle">03</div>
            <div className="title">
              <span className="h2" dangerouslySetInnerHTML={{ __html: title }}></span>
            </div>
          </Col>
          <Col md="12" xl="9" className="pl-xl-5-5">
            <Row>
              {cards.map((item, index) => {
                return (
                  <Col md="12" xl="4" key={index}>
                    <div className={`box box-${index}`}>
                      <div className="icon">
                        <img src={icons[index].node.publicURL} alt="card icon"/>
                      </div>
                      <p>{item.text}</p>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  background: #f4f7f6;
  padding-top: 60px;

  @media (min-width: 1200px) {
    padding-top: 125px;
  }

  .container {
    position: relative;
  }

  .title {
    .h2 {
      margin-bottom: 30px;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }

  .box {
    background: #ffffff;
    padding: 20px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }

    @media (min-width: 1200px) {
      display: block;
      padding: 30px 20px;
    }

    .icon {
      margin-bottom: 18px;

      @media (min-width: 768px) {
        flex: 0 0 8%;
        max-width: 8%;
        margin-bottom: 0;
        margin-top: 5px;
      }

      @media (min-width: 1200px) {
        margin: 0 0 10px;
        max-width: 100%;
      }
    }

    p {
      color: #231e60;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin: 0;

      @media (min-width: 768px) {
        flex: 0 0 92%;
        padding-left: 15px;
        margin-bottom: 0;
        max-width: 92%;
      }

      @media (min-width: 1200px) {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }

    &.box-2{
      @media (min-width: 1200px) {
        p{
          font-size: 14.2529px;
        }
      }
    }
  }
`

export default Card
