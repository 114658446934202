import React from 'react'
import styled from '@emotion/styled'

import { PopupDataContent } from './models'

import PopupIn from './components/PopupIn'
import PopupOverlay from './components/PopupOverlay'

const Popup = styled(({ className }) => {
  const popup_in = PopupDataContent()[0]
  const popup_overlay = PopupDataContent()[1]
  const popup_image = PopupDataContent()[2]

  return (
    <div className={`${className}`}>
      <PopupIn data={popup_in} />
      <PopupOverlay data={popup_overlay} image={popup_image} />
    </div>
  )
})``

export default Popup
