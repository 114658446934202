import React from 'react'

import prev from '@images/icons/arrow-left.svg'

export default ({ className, to, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button button--text button--icon ${className}`}
    aria-label={to}
  >
    <img src={prev} alt="prev arrow"/>
  </button>
)
