import React from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'

import { CertifiedDataContent } from './models'
import { Container, Row, Col } from 'react-bootstrap'

const Certified = styled(({ className }) => {

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 900,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5.1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  }

  const title = CertifiedDataContent()[0]
  const logos = CertifiedDataContent()[1]

  return (
    <div className={`${className}`}>
      <div id="certifications" className="scroll-target"></div>
      <Container className="container-lg-fluid-right">
        <Row className="align-items-center">
          <Col xs="12" md="3" xl="2" className="pr-lg-0">
            <span className="h5">{title}</span>
          </Col>
          <Col xs="12" md="9" xl="10">
            <Slider {...settings}>
              {logos.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="image-box">
                      <img
                        className="zoomable"
                        src={item.node.childImageSharp.fluid.src}
                        alt={item.alt}
                      />
                    </div>
                  </div>
                )
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  margin-top: 60px;
  position: relative;

  .scroll-target {
    position: absolute;
    top: -120px;
  }

  .container{
    @media only screen and (min-width: 768px) and (max-width: 1200px)  {
      max-width: none;
    }
  }

  .h5 {
    color: #747474;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .slick-slider {
    margin-right: -15px;
    
    @media (min-width: 768px) {
      margin-left: -15px;
    }

    .slick-track {
      overflow: visible;

      .slick-slide {
        margin: 0 25px 0 0 !important;

        > div {
          background: transparent !important;
        }

        .image-box {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 60px;

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }

        &:slick-current {
          > div {
            background: transparent !important;
          }
        }
      }
    }

    .slick-dots {
      left: 15px;

      li {
        button:before {
          @media (max-width: 768px) {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    .slick-arrow {
      bottom: -72px;
      margin: 0 15px;
    }
  }
`

export default Certified
