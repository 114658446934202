import { useStaticQuery, graphql } from 'gatsby'

export const IntroTextDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allIntroTextDataJson } = useStaticQuery(
    graphql`
      query queryIntroTextDataContent {
        allIntroTextDataJson {
          edges {
            node {
              intro_text {
                title
                subtitle
                button_cta
              }
            }
          }
        }
      }
    `
  )
  return [
    allIntroTextDataJson.edges[0].node.intro_text.title,
    allIntroTextDataJson.edges[0].node.intro_text.subtitle,
    allIntroTextDataJson.edges[0].node.intro_text.button_cta
  ]
}
