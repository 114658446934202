import { useStaticQuery, graphql } from 'gatsby'

export const SecondTextDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allSecondTextDataJson } = useStaticQuery(
    graphql`
      query querySecondTextDataContent {
        allSecondTextDataJson {
          edges {
            node {
              second_text {
                title
                subtitle
              }
            }
          }
        }
      }
    `
  )
  return [
    allSecondTextDataJson.edges[0].node.second_text.title,
    allSecondTextDataJson.edges[0].node.second_text.subtitle
  ]
}
