import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Col, Container, Row } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

import ThemeContext from '@contexts/themeContext'

import Close from '@images/icons/close.svg'
import Chemicals from '@images/icons/Overlay_Expand_Chemicals.svg'

const PopupOverlay = styled(({ data, image, className }) => {
  const { activePopup, togglePopup } = useContext(ThemeContext)

  return (
    <div className={`${className} ${activePopup ? 'active' : ''}`}>
      <button
        className="popup-overlay"
        onClick={() => togglePopup(!activePopup)}
      >
        &nbsp;
      </button>
      <div className="wrapper">
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              xl={{ span: 6, order: 0 }}
              className="p-0"
            >
              <BackgroundImage
                className="image"
                fluid={image.childImageSharp.fluid}
              />
            </Col>
            <Col
              xs={{ span: 12, order: 0 }}
              xl={{ span: 6, order: 1 }}
              className="p-0"
            >
              <div className="inner">
                <button
                  className="closed"
                  onClick={() => togglePopup(!activePopup)}
                >
                  <img src={Close} alt="close"/>
                </button>
                <img src={Chemicals} alt="Overlay_Expand_Chemicals"/>
                <span className="h4">{data.title}</span>
                <div className="text">
                  <ul>
                    {data.content.map((li, index) => {
                      return <li key={index}>{li.text}</li>
                    })}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  top: 100%;
  height: 100%;
  left: 0;
  position: fixed;
  top: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  right: 0;
  visibility: hidden;
  z-index: 1010;

  &.active {
    opacity: 1;
    top: 0;
    visibility: visible;
  }

  .container {
    @media (max-width: 1200px) {
      max-width: none;
      width: 100%;
    }
  }

  .popup-overlay {
    border: none;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 1200px) {
      background-color: #fff;
    }

    .image {
      background-position: center center;
      background-size: cover;
      display: block;
      min-height: 194px;
      height: 100%;

      @media (min-width: 576px) {
        min-height: 340px;
      }

      @media (min-width: 1200px) {
        min-height: 643px;
      }
    }

    .inner {
      background-color: #fff;
      height: 100%;
      padding: 20px 20px 20px 20px;

      @media (min-width: 576px) {
        padding: 40px 60px 30px 60px;
      }

      @media (min-width: 1200px) {
        padding: 30px 50px 30px 50px;
      }

      .closed {
        background: transparent;
        border: none;
        display: block;
        padding: 0;
        margin-bottom: 13px;
        margin-left: auto;

        &:hover{
          img{
            filter: brightness(0);
          }
        }
        
        @media (min-width: 768px) {
          margin-right: -32px;
        }

        @media (min-width: 1200px) {
          margin-right: -32px;
        }
      }

      .icon {
        margin-bottom: 30px;

        @media (min-width: 768px) {
          margin-bottom: 25px;
        }
      }

      .h4 {
        color: #141414;
        font-family: Titillium Web !important;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 20px;
      }

      .text {
        font-family: Titillium Web;
  
        ul {
          list-style-type: none;
          padding: 0 0 0 23px;
          margin: 0;

          li {
            position: relative;
            margin-bottom: 15px;

            &:before {
              background: #a1d078;
              content: '';
              height: 7px;
              left: -23px;
              position: absolute;
              top: 12px;
              width: 7px;
            }
          }
        }
      }
    }
  }
`

export default PopupOverlay
