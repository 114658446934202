import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Tab, Nav, Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick'

import { TabsDataContent } from './models'

const Tabs = styled(({ className }) => {
  const tabs = TabsDataContent()[0]
  const images = TabsDataContent()[1]

  const slider = useRef()

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        variableWidth: true,
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5
        }
      },
      {
        variableWidth: true,
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5
        }
      }
    ]
  }

  const goTo = (index) => {
    slider.current.slickGoTo(index);
  };

  return (
    <div className={className}>
      <Container fluid="lg" className="p-xs-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="tab-0">
          <Nav variant="pills" className="flex-column">
            <Slider ref={slider} {...settings}>
              {tabs.map((item, index) => {
                return (
                  <Nav.Item key={index} onClick={() => goTo(index)}>
                    <Nav.Link eventKey={'tab-' + index}>{item.title}</Nav.Link>
                  </Nav.Item>
                )
              })}
            </Slider>
          </Nav>
          <Tab.Content>
            {tabs.map((item, index) => {
              return (
                <Tab.Pane eventKey={'tab-' + index} key={index}>
                  <Row>
                    <Col xl="5" className="d-none d-xl-block">
                      <BackgroundImage
                        className="image"
                        fluid={images[index].node.childImageSharp.fluid}
                      />
                    </Col>
                    <Col xl="7" className="pl-xl-5">
                      <span className="h2">{item.title}</span>
                      <div>
                        <ul>
                          {item.content.map((li, liIndex) => {
                            return <li key={liIndex}>{li.text}</li>
                          })}
                        </ul>
                      </div>
                      <BackgroundImage
                        className="image d-block d-xl-none"
                        fluid={images[index].node.childImageSharp.fluid}
                      />
                    </Col>
                  </Row>
                </Tab.Pane>
              )
            })}
          </Tab.Content>
        </Tab.Container>
      </Container>
    </div>
  )
})`
  margin-bottom: 110px;
  margin-top: 60px;

  @media (min-width: 768px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 120px;
    margin-top: 100px;
  }

  .nav-pills {
    a {
      border-bottom: 2px solid transparent;
      color: #747474;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding: 0 17px 20px;
      text-align: center;
      transition: all 0.3s ease;
      white-space: pre;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 32px;
      }

      &.active{
        border-bottom-color: #63a536;
        color: #141414;
        font-weight: bold;
        text-decoration: none;
      }

      &:hover{
        color: #141414;
        text-decoration: none;
      }
    }
  }

  .tab-content {
    padding: 40px 20px 0;
    position: relative;

    @media (min-width: 768px) {
      padding: 60px 49px 0;
    }

    @media (min-width: 1200px) {
      padding-left: 0;
      padding-right: 0;
    }

    &:before {
      background-color: #f4f7f6;
      content: '';
      left: -15px;
      height: 100%;
      top: 0;
      position: absolute;
      right: -15px;
      z-index: -1;

      @media (min-width: 768px) {
        height: calc(100% - 198px);
      }

      @media (min-width: 992px) {
        height: calc(100% - 30px);
      }

      @media (min-width: 1200px) {
        left: 0;
        right: 0;
      }
    }

    .h2 {
      color: #231e60;
      font-family: Titillium Web !important;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: 30px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    ul {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      list-style-type: none;
      padding: 0 0 0 23px;
      margin: 0;

      @media (min-width: 768px) {
        column-count: 2;
        column-gap: 0;
        padding: 0;
        margin-bottom: 30px;
      }

      @media (min-width: 1200px) {
        padding-right: 30px;
        margin-bottom: 49px;
      }

      li {
        position: relative;
        margin-bottom: 15px;

        @media (min-width: 768px) {
          padding-right: 5%;
          padding-left: 23px;

          &:before {
            left: 0 !important;
          }
        }

        @media (min-width: 1200px) {
          padding-right: 20px;
        }

        &:before {
          background: #a1d078;
          content: '';
          height: 7px;
          left: -23px;
          position: absolute;
          top: 12px;
          width: 7px;
        }
      }
    }

    .image {
      background-size: cover;
      background-position: center center;
      height: 192px;
      margin-bottom: -30px;
      margin-top: 5px;

      @media (min-width: 768px) {
        height: 295px;
        margin-bottom: 0;
      }

      @media (min-width: 1200px) {
        height: 464px;
        margin-top: -30px;
        width: 445px;
      }
    }

    > .tab-pane {
      display: none;
    }

    > .active {
      display: block;
    }
  }

  .slick-slide {
    margin: 0 25px 0 0 !important;

    @media (min-width: 1200px) {
      margin-right: 0 !important;
    }

    > div {
      width: 100%;
    }
  }
`

export default Tabs
