import React, { useState, useContext } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import styled from '@emotion/styled'

import ThemeContext from '@contexts/themeContext'

import sprayMachine from '@images/icons/spray-machine.svg'
import close from '@images/icons/close.svg'

const PopupIn = styled(({ data, className }) => {
  const { activePopup, togglePopup } = useContext(ThemeContext)

  const [currentActiveKey, setCurrentActiveKey] = useState(null)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === true ? null : key)
  }

  return (
    <div className={`${className}`}>
      <button
        className="tab"
        onClick={() => toggleActiveKey(!currentActiveKey)}
      >
        <img src={sprayMachine} alt="sprayMachine"/>
        <span dangerouslySetInnerHTML={{ __html: data.title }}></span>
      </button>
      <div className={'popup ' + (currentActiveKey === true ? 'active' : '')}>
        <button
          className="closed"
          onClick={() => toggleActiveKey(!currentActiveKey)}
        >
          <img src={close} alt="close"/> 
        </button>
        <span>{data.text}</span>
        <div className="cta">
          <button onClick={() => togglePopup(!activePopup)}>
            {data.cta.more}
          </button>
          <button
            onClick={() => {
              scrollTo(`#get-help`)
              toggleActiveKey(!currentActiveKey)
            }}
          >
            {data.cta.help}
          </button>
        </div>
      </div>
    </div>
  )
})`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }

  .tab {
    align-items: center;
    background: #ffffff;
    border: 1px solid #f4f7f6;
    box-sizing: border-box;
    box-shadow: 10px 0px 0px #a1d078;
    bottom: 174px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    padding: 20px 0;
    right: 10px;
    width: 120px;

    svg {
      margin-bottom: 14px;
    }

    span {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 0px 14px;
    }

    &:hover{
      span{
        color: #63A536;
      }
    }
  }

  .popup {
    background: #ffffff;
    bottom: 0;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2), inset 10px 0px 0px #a1d078;
    padding: 40px 40px 40px 50px;
    position: fixed;
    transform: translateX(100%);
    transition: all 0.5s ease;
    right: 0;
    width: 540px;
    z-index: 1001;

    &.active {
      transform: translateX(0);
    }

    .closed {
      background: transparent;
      border: none;
      display: block;
      margin-bottom: 30px;
      margin-left: auto;

      &:hover{
        img{
          filter: brightness(0);
        }
      }
    }

    span {
      display: block;
      color: #141414;
      font-family: Changa One;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 34px;
      letter-spacing: 0.02em;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .cta {
      button {
        align-items: flex-start;
        background: transparent;
        border: 2px solid #63a536;
        border-radius: 4px;
        display: inline-block;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        flex-direction: row;
        justify-content: center;
        text-transform: uppercase;
        transition: 0.25s;
        margin-right: 20px;
        padding: 14px 48px;

        &:first-of-type {
          background: #63a536;
          color: #fff;

          &:hover,
          &:focus {
            border-color: #231e60;
            box-shadow: inset 0 -3.25em 0 0 #231e60;
            color: #fff;
            text-decoration: none;
          }
        }

        &:last-child {
          border: 2px solid #63a536;
          color: #63a536;
          margin-right: 0;

          &:hover,
          &:focus {
            border-color: #231e60;
            color: #231e60;
            text-decoration: none;
          }
        }
      }
    }
  }
`

export default PopupIn
